import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import './sortingMenu.scss';
import { t } from 'i18next';
import { Popover } from '@mui/material';
import { Icon } from '../../default_components';
import { CommonSortItemType } from '../index';

type SortingMenuSortItemType = CommonSortItemType<any>;

interface ISortingMenu {
  assets: SortingMenuSortItemType[];
  sort: SortingMenuSortItemType;
  setSort: Dispatch<SetStateAction<SortingMenuSortItemType>>;
  onChange?: (item: SortingMenuSortItemType) => void;
}

const SortingMenu: FC<ISortingMenu> = ({ assets, sort, setSort, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const typeSortMenuIcon = anchorEl ? 'sortMenuRed' : 'sortMenuBlack';

  const clickOnSortMenuIcon = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);

  const closeDropUsersMenu = () => setAnchorEl(null);

  const handleClickOnItem = (item: SortingMenuSortItemType): void => {
    setSort(item);
    closeDropUsersMenu();

    if (onChange) {
      onChange(item);
    }
  };

  return (
    <React.Fragment>
      <div
        aria-describedby={id}
        onClick={clickOnSortMenuIcon}
        className={`sort-menu__anchor ${anchorEl && 'active'}`}
      >
        <p>{t(`translation:my_posts.${sort.value}.message`)}</p>
        <Icon type={typeSortMenuIcon} />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDropUsersMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '.MuiPaper-root': {
            borderRadius: '10px ',
            boxShadow: '2px 2px 15px -2px rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <div className="sort-menu__container">
          {assets.map(item => (
            <div
              key={item.index}
              className={`sort-menu__item ${sort.value === item.value && 'active'}`}
              onClick={() => handleClickOnItem(item)}
            >
              {t(`translation:my_posts.${item.value}.message`)}
            </div>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default SortingMenu;
