import React, { useEffect, useRef, useState } from 'react';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { CatalogButton, CatalogContent } from '../header/catalog';
import { Icon, Skeletons } from '../default_components';
import { api } from '../../services/Api';
import { ICategory, IPost } from '../../types';
import { Fab } from '@mui/material';
import { t } from 'i18next';
import PromoBannersSlider from './promoBanners/PromoBannersSlider';
import SearchField from '../header/searchField/SearchField';
import PostsRow from '../default_components/postsRow/PostsRow';
import AllImg from '../../img/dashboard-img/all_icon.png';
import NewImg from '../../img/dashboard-img/new_icon.png';
import Handmade from '../../img/dashboard-img/handmade.svg';
import ArrowUp from '../default_components/assets/icons/ArrowUp.svg';
import PreOwnedImg from '../../img/dashboard-img/pre_owned_icon.png';
import WhiteArrowSmall from '../default_components/assets/icons/WhiteArrowSmall.svg';
import './dashboard.scss';
import { getAllCategoryIds } from '../../utils/get';
import { localStorageKeys, REDIRECT_URLS } from '../../routes';
import Authorization from '../auth/Authorization';
import TopSellers from './topSellers/TopSellers';
import { ISearchRequest } from '../../services/types';
import BlogFeed from '../blogFeed/BlogFeed';
import useNavigateWithLocale from '../../hooks/useNavigateWithLocale';
import { useLocaleFromUrl } from '../../utils/localeFromUrl';

type IPostsList = {
  [key: string]: {
    title: string;
    posts: IPost[];
  };
};

const apparel_accessory_id: string = 'ტანსაცმელი-და-აქსესუარები';
const toys_id: string = 'სათამაშოები';
const electronics_id: string = 'ტექნიკა';
const handmade_id = 'handmade';

const DashboardContent = () => {
  const [promoVariants, setPromoVariants] = useState([
    {
      name: 'all_items',
      image: AllImg,
      url: '/category/all',
    },
    {
      name: 'new_items',
      image: NewImg,
      url: '/category/all?=false',
    },
    {
      name: 'pre_owned_items',
      image: PreOwnedImg,
      url: '/category/all?=true',
    },
    {
      name: 'handmade',
      image: Handmade,
      url: '/category/all?handmade=true',
    },
  ]);

  const token = Boolean(localStorage.getItem(localStorageKeys.token));
  const isOpenAuthUrl = window.location.pathname === '/dashboard/open_auth';

  const navigateWithLocale = useNavigateWithLocale();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const locale: string | 'en' | null = localStorage.getItem(localStorageKeys.locale);

  const [search, { isLoading: isSearchPostsLoading }] = api.useSearchByQueryMutation();
  const { data: freshPosts, isLoading: isFreshPostsLoading } = api.useGetFreshPostsQuery();
  const { data: categories, isLoading: isCategoriesLoading } = api.useGetCategoriesQuery();

  const localeStr = useLocaleFromUrl();

  const { data: facebookPosts } = api.useGetFacebookPostsQuery();

  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [showLeftBlacking, setShowLeftBlacking] = useState<boolean>(false);
  const [showRightBlacking, setShowRightBlacking] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [postsLists, setPostsLists] = useState<IPostsList>({});

  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(isOpenAuthUrl && !token);

  if (token && isOpenAuthUrl) {
    navigateWithLocale('/dashboard');
  }

  useScrollPosition(setScrollPosition);

  const clickOnBlackingBlock = (side: string): void => {
    if (side == 'left') {
      containerRef.current?.scrollBy(-400, 0);
    }
    if (side == 'right') {
      containerRef.current?.scrollBy(400, 0);
    }
  };

  const onScrollCategories = () => {
    const row = containerRef.current;

    if (row?.scrollWidth && row?.scrollLeft && row?.offsetWidth) {
      const different = row.scrollWidth - (row.scrollLeft + row.offsetWidth);
      setShowLeftBlacking(row.scrollLeft > 10);
      setShowRightBlacking(different > 10);
    }
  };

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = isCatalogOpen && window.innerWidth > 1200 ? 'hidden' : 'auto';
    };

    handleBodyOverflow();

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCatalogOpen]);

  useEffect(() => {
    if (categories) {
      const categoriesIds: string[] = [apparel_accessory_id, toys_id, electronics_id, handmade_id];

      categoriesIds.forEach(id => {
        const categoryIds = getAllCategoryIds(categories, id);
        const categoryName = categories.find(category => category.id === id)?.name;

        const searchObject: ISearchRequest = {
          category_id: categoryIds,
          sort_by: 'newest',
          page: 1,
        };

        if (id === handmade_id) {
          delete searchObject.category_id;
          searchObject.handmade = true;
        }

        search(searchObject)
          .then(response => {
            if ('data' in response && response?.data.hasOwnProperty('posts') && categoryName) {
              setPostsLists(prevState => ({
                ...prevState,
                [id]: {
                  title: categoryName,
                  posts: response.data.posts,
                },
              }));
            } else if (
              'data' in response &&
              response?.data.hasOwnProperty('posts') &&
              handmade_id
            ) {
              setPostsLists(prevState => ({
                ...prevState,
                [id]: {
                  title: 'Handmade',
                  posts: response.data.posts,
                },
              }));
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });
    }
  }, [categories]);

  const onFreshPostsClick = () => {
    navigateWithLocale('/category/all');
  };

  const onPostsRowTitleClick = (category: string): void => {
    if (category === handmade_id) {
      navigateWithLocale('/category/all/?handmade=true');
    } else {
      navigateWithLocale(`/category/${category}`);
    }
  };

  return (
    <div className="dashboard__container">
      {window.innerWidth > 768 && (
        <div className="categories__container">
          <div className="categories__frame" data-cy="categories-line">
            <div
              className={`categories__line ${locale === 'ka' ? 'ml-127' : 'ml-87'}`}
              ref={containerRef}
              onScroll={onScrollCategories}
            >
              <div className="static-categories-buttons">
                <div
                  className="category-button"
                  onClick={() => {
                    setIsCatalogOpen(true);
                  }}
                >
                  <CatalogButton open={isCatalogOpen} setOpen={setIsCatalogOpen} />

                  <span className="burger-text">
                    {t('translation:header.category_all.message')}
                  </span>
                </div>
              </div>

              {!isCategoriesLoading &&
                categories?.map((category: ICategory, id: number) => (
                  <React.Fragment>
                    <a
                      key={id}
                      href={
                        localeStr
                          ? `${localeStr}/category/${category.id}`
                          : `/category/${category.id}`
                      }
                      className="category-button"
                      style={{ textDecoration: 'none' }}
                    >
                      {category.name}
                    </a>
                  </React.Fragment>
                ))}

              {categories && showLeftBlacking && (
                <div
                  className={`blacking-block left-side ${locale === 'ka' ? 'l-127' : 'l-87'}`}
                  onClick={() => clickOnBlackingBlock('left')}
                >
                  <Icon type="arrowVertical" />
                </div>
              )}

              {categories && showRightBlacking && (
                <div
                  className="blacking-block right-side"
                  onClick={() => clickOnBlackingBlock('right')}
                >
                  <Icon type="arrowVertical" />
                </div>
              )}

              {isCategoriesLoading &&
                [...Array(15)].map((id: number) => (
                  <div key={id} className="category-button__skeleton">
                    <Skeletons.SkeletonCategoryButton />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {window.innerWidth <= 768 && (
        <div className="categories__container-mobile">
          <div>
            <CatalogButton open={isCatalogOpen} setOpen={setIsCatalogOpen} />
          </div>
        </div>
      )}

      <CatalogContent open={isCatalogOpen} setOpen={setIsCatalogOpen} />

      <PromoBannersSlider />

      <div className="promo-cards__container">
        <div className="promo-cards">
          {promoVariants.map(promo => {
            return (
              <div
                className="promo-card"
                key={promo.image}
                onClick={() => {
                  navigateWithLocale(promo.url);
                }}
              >
                <img src={promo.image} alt={'icon'} />
                <h2 className="promo-text">{t(`translation:dashboard.${promo.name}.message`)}</h2>
              </div>
            );
          })}
        </div>
      </div>

      <PostsRow
        onTitleClick={onFreshPostsClick}
        style={{ marginTop: '50px' }}
        title={t('translation:dashboard.fresh_posts.message')}
        posts={freshPosts}
        isLoading={isFreshPostsLoading}
      />

      {postsLists.hasOwnProperty(apparel_accessory_id) && (
        <PostsRow
          onTitleClick={() => onPostsRowTitleClick(apparel_accessory_id)}
          style={{ marginTop: '50px' }}
          title={postsLists[apparel_accessory_id].title}
          posts={postsLists[apparel_accessory_id].posts}
          isLoading={isSearchPostsLoading}
        />
      )}

      {postsLists.hasOwnProperty(electronics_id) && (
        <PostsRow
          onTitleClick={() => onPostsRowTitleClick(electronics_id)}
          style={{ marginTop: '50px' }}
          title={postsLists[electronics_id].title}
          posts={postsLists[electronics_id].posts}
          isLoading={isSearchPostsLoading}
        />
      )}

      <div className="second-banner__container-bg">
        <div className="second-banner__container">
          <div className="second-banner">
            <p className="discover__title">
              {t('translation:dashboard.discover_something_new.message')}
            </p>
            <p className="discover__learn-more" onClick={() => navigateWithLocale('/category/all')}>
              {t('translation:dashboard.find_more.message')}
              <img src={WhiteArrowSmall} alt="Small Arrow" />
            </p>
          </div>
        </div>
      </div>

      <TopSellers />

      {postsLists.hasOwnProperty(handmade_id) && (
        <PostsRow
          onTitleClick={() => onPostsRowTitleClick(handmade_id)}
          title={t('translation:filter.handmade.message')}
          posts={postsLists[handmade_id].posts}
          isLoading={isSearchPostsLoading}
          style={{ marginTop: '50px' }}
        />
      )}

      {postsLists.hasOwnProperty(toys_id) && (
        <PostsRow
          onTitleClick={() => onPostsRowTitleClick(toys_id)}
          title={postsLists[toys_id].title}
          posts={postsLists[toys_id].posts}
          isLoading={isSearchPostsLoading}
          style={{ marginTop: '50px' }}
        />
      )}

      {/* <div className="blog__container">
        <div className="blog__content">
          <h3 className="blog-title">{t('translation:dashboard.blog.message')}</h3>

          <div className="blog-cards">
            {facebookPosts &&
              facebookPosts.map((blog: any) => (
                <div
                  style={{ backgroundImage: `url(${blog.image_url})` }}
                  className="blog-card"
                  onClick={() => window.open(`https://facebook.com/${blog.facebook_id}`, '_blank')}
                ></div>
              ))}
          </div>
        </div>
      </div> */}

      <BlogFeed />

      <div
        className={`dashboard-fab ${scrollPosition > 10 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <Fab>
          <img src={ArrowUp} alt="Arrow Up" />
        </Fab>
      </div>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={REDIRECT_URLS.DASHBOARD}
      />
    </div>
  );
};

export default DashboardContent;
